import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import footerImage from "./assets/three-ajolotes.png";
import { useMediaQuery } from 'react-responsive'

const { MerkleTree} =  require('merkletreejs');
const keccak256 = require('keccak256');

const whitelistAddresses = [
  "0x69cDAC3594e942CeA2349399541402e83b24B66B",
  "0xb61AC92368F0cCb282Ff04f7a16376b891e59F73",
  "0xce78418fe02E34638855f07a6346252B71264E73",
  "0x333a1427693a90983f08c2131b0119133c678210",
  "0x8bcf6d7fbc3178f8815039724dfe83685a318f0e",
  "0x5842842CE62e41CaEdDE22B09A538DEf7328f599",
  "0xbae2C97cBaF4e84CeC49b787698c3401985F7Ac1",
  "0xadcadbd9266ff8af778496fcf9b6397b726ff98d",
  "0x0A7FfA78754eD0e9a270b621b3E737a8bEa03Ee8",
  "0xA1800Fdd71993171205aDdaD17249359A983326D",
  "0xF0041f471407b918B6f897E7B140Ccd9E5B52AD3",
  "0x58F66974f68913A7d5f43129e1Ccb1e02236Da6C",
  "0x5779676ee15c3174B7D17b4661cc4d0c5d18ed92",
  "0xcc78B7aDA4d5c9df0C20714660829Ae9581a2833",
  "0x4275460E680eD6ca2331369Bd104506FF6a8d0d1",
  "0x22f90eF50a07C93e3918D094FC1e89736d36B217",
  "0x4a581e41b5239662FA061a9075E29143508E49db",
  "0x9C5236D2c30Ce9676557e61505d0b2d3Ccd93EF7",
  "0x18dE6DA595a75B2643e49730043fC9c7C6Dd27fC",
  "0x56BE03dD85Ed98301058deF81044BA312D0EA93f",
  "0xB62Bebc9Cfe1c44d2A1245a1aF75312405849fb7",
  "0x357fbac6Ef0AbDa90b4b7f21cC6755856BB482C7",
  "0x5223946450Dfb6138f11313cB0EE7f317A04b259",
  "0xcf0D1C0c58659A6BD7CCa4Bc39Bf94470905E1ae",
  "0xFE70451638569Fca5D361469E5C499eA31493d45",
  "0x48E09f945898772D2f042f4827f2c578fEC595ae",
  "0xDEDa54d97DCb31d025D1bE5076006775Dc7C2A02",
  "0x4c457eF73384F2EFd0e29D3E3b2d38f6071486ec",
  "0x2e3D5Ea923DA34bF27b45c27114d4C504D51Afdb",
  "0xb13d365F57Ac776Df4D7141dB97C92dFB1924695",
  "0xF21e420728484Bd2998E0EFC2Fb86d978c16c942",
  "0xD96D3478F2f1BBAE19f319FdF35387d059F8A43c",
  "0xcC04f2Feba79117F5219d36c38A5BC416dDf3Df6",
  "0xf19ff9fbF3c4F9A483404FC89D48b99893EAfE42",
  "0x6EeBa3B95FdA5275a7C4CB04c80765687d52FB91",
  "0x6B89F11f8505fb83c53d370C22edA9e7a7FDb25b",
  "0xfA47a50035a8202e37BB00BfbbBC791dFFB7ea15",
  "0x03cb087919bb6b4eFD5726008cacEDd35eBd2Eaa",
  "0xE064d967c08CEDDf129Ea8390eD5F2b0E6458b63",
  "0xb7e5e4194a201A114338352e7ce3dCEe9a69AF1f",
  "0x09106FA78B9e470D792baE6957789a66A9937788",
  "0x93771e292cf922aBF8B9C182594ef2Fb66807C55",
  "0x2471f0d7bA692B23C0E804c1c4edebecae6a0159",
  "0xAeedA3bdF0d7920EEF71E01CD33d1205232b6dd8",
  "0x8FCeFAC865C2c00DeF926ed2686d9F128D567140",
  "0x57A0936476E36FC92C4256d76888dE3465F55bD7",
  "0x2824132E40045F405c9f9F5B86CA83e0055a5766",
  "0x82DA761284C2076c6401D43E61cA41313F328Ed7",
  "0xfC1688EdB753869bC51eBcd74d241BA9b13781EA",
  "0x2310961e926A5C0A5d80EfC60Eba8c81680238e4",
  "0x6BfE5e7673C66aAd37fc016DB17028faE9cA7a07",
  "0x8A45Da877928F1F13A88405DbF4c727aa195Df1d",
  "0x02866544D20AB0619ea0724C8b3E5c24Ff52F944",
  "0x2bd233c99b4dF27e73341a1D576c3D73F4501D12",
  "0x2b11B98F18F161A6520E18768C945f884576044f",
  "0xcfeCC781180e1F4ee7867749e6c6e42A19D33081",
  "0xFB6aa6C82671aA8de67b5fa4b5A930AB3b7b6bF2",
  "0xEB5eA632EdA96F03F6Ee088a889DAFa1346c6b24",
  "0x0811AF32640D5e10ecA88a0c2B531f25813420FF",
  "0xb5552991cE7e8Bf11cC93285764D355BdbBdcF4E",
  "0x3D823F60df235Eca40C4CaE294d3D459fB7a2d24",
  "0x2902A59FeC6dF5511ce6C0C13B52A7eAE6A65EBd",
  "0x675Fc6A8DA020dF1FB6203724503838865303e2E",
  "0x490fD25e239cEdde2844ef8767b7a3bD6A134A62",
  "0xC9C32dDFD4595864f2a2C3e1D8D14c69d52D6172",
  "0x39f8590C606D2291CaC624b0FC8a71193092Eeb7",
  "0xCEe81DD2F32F35E9A0167A123A3d4E43bD8A626A",
  "0x4acF8167A269d866A1d65d31b35AF6cbE6bccd13",
  "0x6BF4A870cCC80DD31B9F56F4d2420b3530775C53",
  "0x31B797C491e58bd0e64710252bbF9613bB660A45",
  "0x86EbE5C148a68737b54bfc00D094766fdEFaE941",
  "0x3E127654F95b37F181B4E36de7274e7639805dD4",
  "0x09AD3074dF6CB2732a4DE5a4f37d11eF9DB7E4d6",
  "0xa1cF1Da87B370b18c9DaD3F64c8DDE8200CBF98F",
  "0xc3799c7c850460bCB5dd856808B8AAbb6550B084",
  "0xE8B384F109f3cc92934dcf744a4f0E3535074Cb9",
  "0xFf12802fe04cFD092886061d4e901112f84018F8",
  "0x6AF7e2F751aB0e8CaC4b6e0222F76D7534A810A5",
  "0xB9df33a5E45E4FB577d4BB58a8C380D29987E4C1",
  "0x8528AB960937e65013f5C106B9ccdc14837C339A",
  "0xA7272489312009d4AdB898501F8eD2621D5E39B5",
  "0x3342BbD147021bCa926f774FC9c33fD3dfE1F447",
  "0xeBbED1387B1cab348fD760C05B5676E23a84b124",
  "0x40c82f2e97A4dbFAD0111872Bd9b206Da9cAfEfe",
  "0xA3cF6452074e1b0bd02C10d1cC974d5A71D903E3",
  "0x61836cF8D527C1B671b3c3d92afe1061D5A76E84",
  "0x81C3A60CEf29885a36eA9a52e85Ca1FB6a57c6e4",
  "0xF5f1B35fA3d0Cb264E926abd58dd0789302C7d20",
  "0x7576876C452140843d901acc332f45ECFbC054C2",
  "0x1B3844ed2215990FDc99d9118b3805C8Ca315151",
  "0x46B0B394440eC6BdB2A85C147EBA23A71E6ee33b",
  "0x69e5b20f0315a0D4C0d26C87fA9A7aF18004775c",
  "0x53f7870C3ea58d0C52E5cF0794095cd82De2f712",
  "0x0C406b8b7507BD78dD795323B51115ca2BED9558",
  "0x1a19598FcaD0124CB1f29FdDf194B883671A365A",
  "0xab711402f334FE7Dc1f463cBDAb34DEE587F30Ea",
  "0x151fEa4F1321E6Ce02925881471D6C2A8C82847c",
  "0x7c6DefE4C0ebe8eF51C1D7d8ec9c901bed1d901A",
  "0xe55A4ceC9228A3E33f23c4c6B83E4AF8f749860d",
  "0x3C81bE9Fb4d21bfDd673eb6295e5Ad42Ab09f662",
  "0x9E6e0D686C6f545Ac509F35fA6450f0d687ed28C",
  "0xc7B0A471dc0dA49C5FBC8069F58044d85C6F5aA1",
  "0xaEd742019C2577D6e42872e9418E74c013dC4E9D",
  "0x05D215Ad27F79996dE2fb90E37C39a2d06F17B28",
  "0xA473462CBe042b2C4872D0fD30091b8b45F2e28C",
  "0xb02620bf812c8bb9c4d3484AA76f11f7293C11A4",
  "0xb804F540DCbD6AC9C21f3eC87370E6B09a8e3d6b",
  "0x3366C10202322F961675f80be28193d7F848B018",
  "0xE1946AB14f0BA104140f74b1329B365c285b35A3",
  "0x34b2dBA9FD044dc3aa3D3245C908986eA4724A36",
  "0xaDd67B736945391663276ca2cb3Bb6D8cB5c571f",
  "0x600bA6CE224324AeDd2bB60B22F7F046E1c2AA83",
  "0x57078e5d94556AEE8263D2955028b232aFD5BF23",
  "0xe800785F8E39C04F32dD1B1eA7c60d4B50c69C57",
  "0xCf292199D5220C2D7d41609823E59919Ac2ffA02",
  "0x836cE3DBabe3e2eDbC324e8889e120d6523948b7",
  "0x1A991a673f02cc3fF01496885893f03ba7cFD432",
  "0x246CF2aB3b1b26f986Ee46f5d512841a9006Dc04",
  "0x44e8F42C0174659185BF866eAB22bb7289DFD175",
  "0x3bC8d5D562782D7eBB210373e8D2C62F5eE8fBc3",
  "0x18Df3200536dA93161789Ce82Dd4b43e5D3239e0",
  "0x31bf7f3D5f612aCB608C7af3dcF8e5Ec4039691e",
  "0x997266AF68831d5de31051d44Bf8BD920f8c77E4",
  "0x7000F3036E275BaD39c6a4b65124AA600046db95",
  "0xD8876b754433a48F6577438202b2303765B64d48",
  "0x494498eF1d070a3c8e20435132C27c64f6891F1B",
  "0x096B708dDe3d4a549C038bCB64354b0e7E5AA884",
  "0x4dfF5B015fe2FA01cDf8E0c843C8486D866a812D",
  "0xCDbE0603B4875fFe20Be701b8C037e1cCceae66E",
  "0x92D70C8126Bcd36a882B51DD0deAB61AcCA2be69",
  "0x15404010fbfE378c7E49781AFeb671C5070b1Ac3",
  "0xfE0A554913AaFC9ab0E8fAB4fC4f22F1B28A3989",
  "0x1c759Be971B5D71869A57949C57c524d0d869c56",
  "0xfB2C47c13c283e087Ec1254E220d9736f4DEea48",
  "0x82Dc4A5742263335b5CAc43D7b5b98CA3b3ccE32",
  "0x2bd26C0De64AD2Db90f23eA2B6bE01ab3c70cd81",
  "0x7eb05FE176f31b011f0ecC93bE3873893ff027a4",
  "0xa70B1a6A096860c31c938bd7a12e96F896B9eBe4",
  "0xa25e560C3Ae70404e05dbC8Aeb1648a6D0A7b764",
  "0x9878e405e7828fad2058fc29c1356a2a19cb878d",
  "0xa292d80a652131e3560c03B959667c6C8B48E12F",
  "0xA78D9045FFF4Cb2b31c7927414CeadDc282927f7",
  "0xa7c6860770902AB34Ba69a5d8D93b34F56833A99",
  "0xDA718f6f8EE29b173Df5e9b7504be32e31a75AC4",
  "0x84085A829F9F6E9fE540Dd9716a25D649242CBCd",
  "0xd9FEaC25B462210d9f8DfA2B0d787fE2de995941",
  "0xA4459D188993Ba5cA080fee4eeCc5df16E6631B9",
  "0xE61e703D4259518338c0fF7a639E4b535F4944ae",
  "0x31D48ED352405ea52c3757F494DF23F31d19b1D4",
  "0xFA2e151D2240427f588b2f7Dd2904338c3c4C0ff",
  "0xe9ad72d3c8261897e7c2c873dc04f3095ddedb46",
  "0x4F40a6c1b8b1D76a335cdcAc204E569aa97662a0",
  "0x324E25f30Ed4E93D809050A90a214Fe297042683",
  "0xCA91265749B1FF7DC89874a5185c02E00185F829",
  "0xc79446D8F4291d663030F136A84d76037918159F",
  "0x5f9f15E3d36C66BFb6C6bfAc3e77b3Ed06Ce4901",
  "0x9243bE1e559543A3cdF5Ef527b5Ac4DaFeaf4078",
  "0xee0B79B6dFaA65610e71f6d3dF1edA800f9E076A",
  "0x092e394368F42d0f47a73442Fa770D67279Fa0D1",
  "0xB9BF1973D4056Fd819030653E593A7Bc84838f11",
  "0x4e29230d1314219cD4F4D5672527dFd1Ac03ac5F",
  "0x67194dFF55D83f8DE72F90916Fe130a42B8F10ec",
  "0x87bd998c174d39592ccD0E2C83fC7713984F90E5",
  "0x9f30D15230e08D2A384B7B054a357ec3845D9353",
  "0x92E5a75675F9EAeb2Cf549d3D0e1b8aEB7fC3627",
  "0x35Df42be15Fa9Ac9C6bb6C45346d9A996189e392",
  "0xC3e555674C1eC0D995f9b63d5d46E7efCf536298",
  "0xF22B40315f5621Aed62DC323E1CF58Fa3c7ec149",
  "0xAf2E7831513022a901748eA742dDEf6F53fE21D8",
  "0x7B03bBda3748f83fFFC4d46876d522C9013346EE",
  "0x25d52F955d51A8382237970128E8777Be9Dbe5A2",
  "0xC334e9af6Dc95e3D4E4fdD5AEbC13F37b9874F93",
  "0xe8A9f151b624823eaBABaA31684D4fFDC2fEe0b3",
  "0xA0041111Be534112542F521bDD8d0B47435CaDCC",
  "0xBD0823Cd5F6cAf836032d40dc41AeB6199430FF9",
  "0x0b1327Ca4bF10c35A5f8aAD7eBB56B74432FEe18",
  "0x84d4560bc9681F39945b06Fb49180854c496ccBF",
  "0xCB1DB08e64bF75bAFe3C9D891Af2d1956A6F95fe",
  "0x13d43CbB7454Df5e9cC9B70409344366C16dD54e",
  "0x7C2fd57dD146cdC6dbD6c01e94b5013299925E7f",
  "0xF9ef6Bc7138A8689FD7009Aa278415A43f06ff87",
  "0xfdc2279393f431fa2da77d0e8944b9d03c53634d",
  "0x9b6F71f812dD6c9A51cb7070b1F72d2788980f39",
  "0xCFc7361700F1D0A3Da00EDE7Ef9d9Fb8702F8B0d",
  "0x2174adD3718f0D69bC11d67719eF24585243802D",
  "0x8244C7d95d015178279023Ee12B824fd4E9ecdFA",
  "0x7502C4c57F49a56E7b63d992dE1690861A187Fc2",
  "0x596DC4Bb87c9D7dc4e227906b2fd6651AE2E6FD9",
  "0xf3a4Da1B3aB950fB0742ed33619976514C6f7Ad5",
  "0xc011CF6f5D4368879D9571a20072f4D186B4Fe10",
  "0xF011C2d5326d013dea96832B0868429109D80F75",
  "0xd7f6796Ba3dbd118C55B481ebc9AF16843142A6A",
  "0x27FAc7Ef535d353f12815EddA2113eef985Ba397",
  "0xAB0537383AF63079b537e5BFaF4bAC304C2952f7",
  "0x41261DB0Aee1EC0C62b5F9034125c8465307C7Ea",
  "0x61955Da7b9B3A56aCb52BC55c5F72dB83B947b5C",
  "0x755934665c2fB08aDE548Be1Aa40D007c7Bc6fE7",
  "0x5819c646ea505e1E5000B25065a42abfbC23d941",
  "0x010775ddbde840291bc7394719e0519cff10fd9e",
  "0x78ebFc0903F43a8ab7245aB4641E6f3C361c997c",
  "0xF54035bbE64BDb2E01e5d073258eD8d914BA81dd",
  "0x9c3E6e076ED12d0555892B6d05623F9f4C2226dB",
  "0xA7376a5375028ce0Db5E2F4608AdE40C6548352B",
  "0x04A65Ce7d4605F48536D07BF15d1299c4757aA3B",
  "0x4E583c143b33d161dcFad5362685443E41C43b63",
  "0x184e5429E4c54C00484FbbB2e5147D1Fa2e38983",
  "0x94a1CDbAb03Bc46C89968f47a54b1319e537DB34",
  "0x6c5950fd59AbC25E0a65f48645C2d88156911810",
  "0xeCcb0e685f152F8BBaa96456B6BE62884f0ff822",
  "0x3d50E32DbF098a570bf41C36179cb1176F104AB1",
  "0x1069252185E219fAd724deB3143d72818ceCE681",
  "0x1457362DaD47cbD5A00c71FA45BC5C47583B2018",
  "0x699D2351295b6636913Bab67BdD0136e9b39A9b8",
  "0xAA7D0dD319B75Efa391a6234BCd86E7C7f2380B6",
  "0x58d409c5DA81Bc27b3b8B104E099f43Ff93e2747",
  "0x2116Ca550241a7FF92935E68e7786c94394F5c12",
  "0x165dC45aAf503962E7De8f7468562c2BAa0AaFab",
  "0xdC245E0ECBCBC4210Eb6a20530f57b9a944281B2",
  "0x17FEA987DF576FB452f74D2b81A250e6A148Fb0C",
  "0xc390B4e67E0b08A7Fdff3bE54C3eeC3350e769fF",
  "0xE5098481D43A90fcfBD3b969d2F17Ab490d65638",
  "0x62Ed07A42cB3E14088a22F1df9b4e8Ebd24DDa37",
  "0x740779061b59c659a67F5b1031A88f95eD7Ea6fd",
  "0x1A6d4D713bEf399B2F742AF2Ea7cae0240344438",
  "0x204F73026d32940C039C4a936549179cF1481812",
  "0x499b27397616CA29778719635A36c650d264759B",
  "0x385e37355B911DCCC8b644FB6867Cf7200D344A9",
  "0xa32c31ED7b660D14C54eca0967628e6bd750E23b",
  "0x37D9F3561099f9EB239fF5688E0F57bD6B3554D1",
  "0x98D604aAEE066B580ea095D453DDc852DE17915B",
  "0x7A62db298a344c52FAEF353DA57C903aab8D31Bb",
  "0x1C33D220C79e337385B74Cc7859F1b65e9b9fF8d",
  "0x0211f13b5D3A3164c29FCd70a7788b3Ef106E347",
  "0xB4E22C8614DA6BB9B657902f508DD3f8Ec3c8F7f",
  "0xFC368e16Fcd14Ec414Ec766fF060CB8e3D60Af6d",
  "0xbA7754B869cA836D92D6d72aD39b89388CA8422d",
  "0xdc72b932f82cbb4E1B916946eb069C251E66f6A7",
  "0xeaDa59abEfFc7C8fF30D2bAc1D20E021564B0744",
  "0x3122456A764807966e11D8d7a299cB3c3d8543Fe",
  "0x2Bd658b8c54B5067C053058Cd349731f4055d518",
  "0x17F7a9Ad5821234019DA382535bD272231be6cEf",
  "0xA1354c5feEDd0593A7a444994cdE1609F8412410",
  "0xcBD5B00bD42458C3Fe4f47F5ED9f53d3B47a9174",
  "0xa63B122D8f4ee169aD8Df9A61B8Ed6b6700E2D98",
  "0x9976B409ee97F00BD33b26e9178407A4f9d95F70",
  "0x0A51245400CdD484Da514617a160dfA399E38021",
  "0xd079429401CA47b190dCAf2853bb8Ccde199B563",
  "0x300870edeB89F6458D1fDDD085695b039717e055",
  "0x3d673b421109fc4D1624a6157f2DFf32bA8130dE",
  "0xD16091Bbd6bB0f76241dE83E4D496e1773089E33",
  "0x6a58347Ee2aA5f9Ffd0EeA20888D93A5E6700EB4",
  "0x063314c34f83Fb61504EB2579EB09875fbC4698a",
  "0x57b69C0809F2827784348Ebf1E53e3Dc94f4076c",
  "0x3A4AF33BdB11Bb512e7d75966C81Df34e6DE543d",
  "0x64e97A012fB7727827BB33822b8eea5b47623867",
  "0x8879735458B2DD903d6AD03deeCFE932152911F1",
  "0x9f560788819681796971F3A2b7b0FE4687944Cca",
  "0x553f9d27C1BC1413D9BDeE3A41A090471d15211f",
  "0xd554BA4ed5A2e1D4ab72768CAEB3121E97E13871",
  "0x4c4a3d32dc8f6fC7830Bdc1b336C5f74b2d27A95",
  "0x43F51E22b3E51f04E6A5F37ceD55563a39Adc921",
  "0x09B1A0eA6960cA5E4eB549F3D4A4c3cD931C25e6",
  "0x61d91A1c91C105cAC5Bde57fc9C1b25c8A3914F7",
  "0xD0257B24b5ad66784674654AD97446828ebaddd3",
  "0x8B319E262D6923Ce47f5D5b4002eBbB6A86D26ae",
  "0xF87b2F5e61115478C7e09b0F2151b7B35F9f91Bf",
  "0x0BF72503363c4A468af815259196861EBcC1e234",
  "0xbe3E60B1D0Ed09578140782A53Bc198A30849fd6",
  "0x87AB344C02f8617f8E15Ff9Fc2E5f3d4FE491b6C",
  "0x1c1839748CeD0740B79e69D01c4a9b3e9E80eAB2",
  "0x19F41364EdBb929C96ea85829699e3F12c922088",
  "0x0CD0Ab7D62c45B2FCFDA81BF999c879cE6416E77",
  "0x2004a2511606D1620016945E6454e5831cd5ca35",
  "0x97d238F2DE4Ee8Dc367dC280854B398A06788E87",
  "0x39cB0D067201e3e55b2fA2747728A7fcd8c5A51E",
  "0x689489DBc8D8164F03ddD54570862B674B2aaBf8",
  "0x13a49f0aC5Aebf7B559d94340e9007b5435a56a3",
  "0x2Cd1f38EeA0F6204B5FB48e2686eC99eB4c4E85A",
  "0x53f5D30BD35ffD9A4d68767e0e2CFf76e29c287E",
  "0x7A3a8aA9bCD7091a9C16e56d7C0247877c43144A",
  "0x0ED99964B9Cc1F5437b53AB2391e07FcdE86d93D",
  "0x10ab7fedAeD208c54f56079B05df84d389C87FB4",
  "0x933F6597569CadCb04CD4a8F36a947F46Ea7DE0d",
  "0xf6E48828FC8Bd031Fac0111b235721d2C643adF8",
  "0x3ae855532220E0dEC10B5003Cc006E5BdA78286b",
  "0x9837B000D0866a21C5De9Cbc675abC2346925a4B",
  "0xFBbD3B147DFdd019Bb2aA657c7E8fE28E453ed7b",
  "0x26Ac19dB514f62c2195d0c23EF981ad2ba2AC9B7",
  "0x7011Ac5700be30C4Da65697f26Ff193d9005f9B6",
  "0x3edDdb9A846469406D4249D0B97E400D9D1BF4aC",
  "0x557b571eCc3C9d6F22207699f7B697Ed12417cf1",
  "0x5A124dBDbe4ac0311cEdFeeba42578C38D3C2f50",
  "0xc30810e54DAf78f247Cc2085ed51DfA91e6e3f0c",
  "0x4139956544748AA5ec1007FEdf8786E034C13e37",
  "0xA593B370dfE8883db696112b3968694B8BDE810a",
  "0x77346841D0AeeE9e3389F687638BB3a8e9fEFBD2",
  "0xDc4f9b530237629213D0d0c12010Affa5C40C39C",
  "0x70a685082Aac0902E71B166fC67765edA9265EA5",
  "0x27CAd702e23EAC7a752c7F2f7B8965E34cB53133",
  "0xd56d90EFDD2a4B5883f33CF72fF277F3a2cD4133",
  "0x8c6B20b92AE7c0B59E099ad3327aF093E9955808",
  "0x917B2dB7aFF11C9013Bd68af8BBcc939c8851a8F",
  "0x02f75d355a940BED122A5ed25642df51AA325671",
  "0xa02084bef9c5Bf970d816d2DCde7d3d91A879062",
  "0x7034FCB2dd7cB1Dc2f61EB6aCD8Ec2C08f105BcE",
  "0x1340Ae5e6BF7d689f4Eec2CDc1148FadC44602a4",
  "0x6D382c50Ab8f935d35ca61F3F63357531d3AdeaD",
  "0xEADD2E40AE08277F96c4d1da5d12417233a88b58",
  "0x9eFF2Fa0e42F172935c9BF1b2C6b4d78caE30a67",
  "0x0B88b683f4154901874d75c644c8b03Bf8f5dF49",
  "0x38D53Cf34E55d406F7aE2A2628A5b2B1a897B214",
  "0xd88cc843d179d18346e60d875744185efe4e10b8",
  "0x4810c4f0F293E8BbD108B2Be90d56D94083C5Ce5",
  "0xBCf50397A32D758f2F50cF5632cB13Be16f320E2",
  "0x8110822e87550d3a56b7f7e0f2e0300eEA8cF37C",
  "0x93e872693de02121f51f964e330D1a18ac83c1d7",
  "0x06bBEEc1FC4137D886c84893ab2b2563c2daCA64",
  "0x75375A3990Dc140df64c23B9dc6126D655D91723",
  "0x9C592E85b72CAB31028473500A6A5efa82507263",
  "0x0C0283B99475145608f54c6b6E0337de8f1Fc047",
  "0x30f0D0d219f24AbAD649837a042205600AF060cb",
  "0x1E68Fda3f454Cef669A00af5512fFB0aDc453d2C",
  "0xa07b5B617c32a5256143d6b282D36297292611fE",
  "0x174DCE6483937732Fe9742691c75675A199B61C8",
  "0xE928CB31c96fAe275b7AcC30afb7826BEbB9AD59",
  "0xBa708C4cd9bAff10a24374FCdb8865083943b6f2",
  "0x6040BC45E3c3467dad66e869e22fCb3ef6133ffF",
  "0xbcb3e90005d207076Ccb5F66397b46171fF79F83",
  "0xc4878A2D58B892A07efc041550E7741222F7F202",
  "0x0B9BE2fd7DA0A848410F17c1B8aed5DBE67166d1",
  "0x9D537Bdf35B94ecF5c4A1509866E0E9875E5fCe3",
  "0xf28b67525Fa224E1C4ac1A43f685ce802d221A34",
  "0x061B0AB81f5C98451b574C171170b50B0217eAe0",
  "0x0a53b4Ce6c9AD6F1970c3F2e29c2c62F773df693",
  "0x87EEA959c8d15456B4f39Ff81e3Bed573e9405Db",
  "0x128570f3f537a41679D5E04564e1DB5c8772a0fD",
  "0x18f6487519ccb9D7b6d024D5741057c3EcfA4532",
  "0x89173E1D9e4Ec2933acC4F8A9631D8b12e5d3306",
  "0xEC6dE0a4D836Dc912b82AB8059523485216DdB73",
  "0x61b0784c38469D653834f881B7d10154fE6d3DC2",
  "0x524160191557DFc306Ea6121a03D027902C5a3e0",
  "0x3ea6344C8447298E04Bcf781BacbbCA341C493e4",
  "0xf1ea61a7c54DFA3d153534dEf64077379c31E05d",
  "0x647Ce5af222033a12eeEf222C1655A3DF7926227",
  "0x916bF70253b9d73c5fAE49e6E8b01E5E523CA41e",
  "0x208704ab143EE9bCdA07Acf03A7afBC045b71432",
  "0xe263c5d4939D677ae2C0682044333C9a3C88d0BC",
  "0x34d1fd9b6CCEa210D5834DBFa08af4d8C8997183",
  "0x2670731231D805008b2d5C2ec34CF79E2715e072",
  "0x71b93702BCb85A8D716da9958b42eCc7ca671b1E",
  "0xD5efFF261c5cDeC61fa30E04EE5728B268c3cDEB",
  "0xE6F63FA45C043665797869EC42F3D4F4b2b855eB",
  "0x1E8FF2417046d80806B9A65ea0794E902A15976d",
  "0xD0CeD639B3B3C817da45B98d0735711c1d2f7E78",
  "0xF4fF044B4AB5DfaD99B2E566891093a26eC8dc54",
  "0x6472Fb9a3c502F44290EeAB73617ECd5Aa496F69",
  "0xcc52dEB17541b4b05e1fd5C8ae9Dce47107AC929",
  "0xbC91222C7DB724F9aA99eFf7b493Ec37FF241341",
  "0x720F3da95325e9cd2D4d246Ad5bDA7Dc4A94F9c7",
  "0x39f5D54718b9b78B0Acaf6cb8747456c5fc39d76",
  "0x9fF099113C37E54af22E380328D8a3d93f027228",
  "0x84042D0EE7a1fCE0dB3968dcD1646734b0FefA9A",
  "0x9E29080920D5Da26bCB56E550022F5fEDDba3183",
  "0xbD4a2c36F8e27404f31e34F67047E5022Ef0b95c",
  "0x564c09ca810113a3FF7182706bBdD77fc99338B7",
  "0x0B81413123E546b2E066B507c55DBAc386978be2",
  "0x900A995aA59Cc5aC2618Ae0513690b1009ef0175",
  "0xa8C4708C0DC860a93505cFeF9B13625E113d8300",
  "0x519d58B1015c4e202776E8dcEF810CE81e46aDA2",
  "0x0b3f1B63c14B88eC6351eE1E7d8716e8f3F216BF",
  "0x0606c42F4053C7623a2907d9Cdc73ad60EE6ce16",
  "0xdb3e54c9834487Ca031bAE3e5b7F6cb08Eeb1f87",
  "0xc14417afD25C762Ca4830586cb3fa78CC5232f21",
  "0xD8b30f8998E7e3abC5d9eC2eF06073e2d713FaC5",
  "0x700BB8c8b1C99DF37c2a8492419f8bbbC1BA0e03",
  "0xC43B1a5912E31cB19617585845dFF9Bf729c8450",
  "0x5551c3560010e9B639cB1C4aAbA888093A2E36e1",
  "0xa9c89d1b8fe4df27103b623cba7a2a2c6a34a4d1",
  "0x19C8E74476759D2DF3F59828278b9Cba6d9083Bc",
  "0xA21107c9957594671aa8D6bb0E9d39bcec81e87d"
]

export const getMerkleProof = (whitelistAddresses, address) => {
  console.log("address", address);

  const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
  const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  const buf2hex = x => "0x" + x.toString("hex");

  const proof = tree.getProof(keccak256(address)).map(x => buf2hex(x.data));
  return proof;
};

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  export const StyledButton = styled.button`
  font-weight: bold;
  border-radius: 9px;
  border: solid;
  border-width: thin;
  background: linear-gradient(-18deg, #35A0F1 30%, #8BEEDA 80%);
  padding: 10px;
  color: white;
  width: 130px;
  cursor: pointer;
  :hover {
  background: linear-gradient(-18deg, #35A0F1 50%, #8BEEDA 70%);
  }
}
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 150px;
  @media (min-width: 767px) {
    width: 200px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    const _merkleProof = getMerkleProof(whitelistAddresses ,blockchain.account);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    // blockchain.smartContract.methods.whitelistMint(mintAmount, _merkleProof).send({
    //     gasLimit: String(totalGasLimit),
    //     to: CONFIG.CONTRACT_ADDRESS,
    //     from: blockchain.account,
    //     value: totalCostWei,
    //   })
    //   .once("error", (err) => {
    //     console.log(err);
    //     setFeedback("Sorry, something went wrong please try again later.");
    //     setClaimingNft(false);
    //   })
    //   .then((receipt) => {
    //     console.log(receipt);
    //     setFeedback(
    //       `The Axolox is yours! Click here to view the collection.`
    //     );
    //     setClaimingNft(false);
    //     dispatch(fetchData(blockchain.account));
    //   });
    blockchain.smartContract.methods.publicMint(mintAmount).send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `The Axolox is yours! Click here to view the collection.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 4) {
      newMintAmount = 4;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <s.Screen>
      {!isMobile ? <img src={footerImage} alt="three" style={{width:"460px", position:"absolute", right: "0px", bottom: "0px", transform: "rotateX(360deg)"}}/>: <p></p>}
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24}}
        image={"/config/images/bg.jpg"}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 24,
              borderRadius: 24,
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              MINT YOUR AXOLOX
            </s.TextTitle>
            <s.SpacerSmall />
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL} per mint
                </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            {blockchain.account === "" ||
            blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  CONNECT
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
              </s.Container>
            ) : (
              <>
                {true ? (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      <a href="https://opensea.io" target="_blank" rel="noopener noreferrer">
                      {feedback}
                      </a>
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "MINT NOW"}
                      </StyledButton>
                    </s.Container>
                  </>
                ) :
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      You are NOT whitelisted!
                    </s.TextDescription>
                  </>
                  }
              </>
            )
          }
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default App;
